// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/notifications/components/NotificationIcon.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/notifications/components/NotificationIcon.tsx");
  import.meta.hot.lastModified = "1731679026365.643";
}
// REMIX HMR END

import React from 'react';
import { HistoryEntryType, NotificationType } from '~admin/generated/graphql';
import { ArrowsRightLeftIcon, UserPlusIcon, UserCircleIcon, UserIcon, UserMinusIcon, HomeIcon, KeyIcon, LockOpenIcon, EnvelopeIcon, PencilSquareIcon, TruckIcon, BanknotesIcon, TagIcon, ShoppingCartIcon, TicketIcon, XMarkIcon, QrCodeIcon, BuildingStorefrontIcon, EyeIcon, EyeSlashIcon, CheckCircleIcon, XCircleIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
export const NotificationIcon = ({
  entry,
  type = NotificationType.Notification,
  iconSize = 'small'
}) => {
  let bgColor;
  let iconColor;
  let iconType;
  let iconEntryType = entry.type || entry;
  if (iconEntryType) {
    switch (iconEntryType) {
      case HistoryEntryType.OrderStateTransition:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = ArrowsRightLeftIcon;
        break;
      case HistoryEntryType.OrderPaymentTransition:
        bgColor = 'bg-emerald-200';
        iconColor = 'text-emerald-600';
        iconType = ArrowsRightLeftIcon;
        break;
      case HistoryEntryType.AgreementSigned:
        bgColor = 'bg-emerald-200';
        iconColor = 'text-emerald-600';
        iconType = NewspaperIcon;
        break;
      case HistoryEntryType.OrderFulfillmentTransition:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = ArrowsRightLeftIcon;
        break;
      case HistoryEntryType.OrderRefundTransition:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = BanknotesIcon;
        break;
      case HistoryEntryType.CustomerAddressCreated:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = HomeIcon;
        break;
      case HistoryEntryType.CustomerAddressUpdated:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = HomeIcon;
        break;
      case HistoryEntryType.CustomerAddressDeleted:
        bgColor = 'bg-red-300';
        iconColor = 'text-red-800';
        iconType = HomeIcon;
        break;
      case HistoryEntryType.CustomerVerified:
        bgColor = 'bg-sky-200';
        iconColor = 'text-sky-600';
        iconType = UserCircleIcon;
        break;
      case HistoryEntryType.CustomerRegistered:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = UserPlusIcon;
        break;
      case HistoryEntryType.CustomerDetailUpdated:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = UserIcon;
        break;
      case HistoryEntryType.CustomerAddedToGroup:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = UserPlusIcon;
        break;
      case HistoryEntryType.CustomerRemovedFromGroup:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = UserMinusIcon;
        break;
      case HistoryEntryType.CustomerPasswordUpdated:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = KeyIcon;
        break;
      case HistoryEntryType.CustomerPasswordResetRequested:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = LockOpenIcon;
        break;
      case HistoryEntryType.CustomerPasswordResetVerified:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = LockOpenIcon;
        break;
      case HistoryEntryType.CustomerEmailUpdateRequested:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = EnvelopeIcon;
        break;
      case HistoryEntryType.CustomerEmailUpdateVerified:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = EnvelopeIcon;
        break;
      case HistoryEntryType.CustomerNote:
        bgColor = 'bg-amber-300';
        iconColor = 'text-amber-800';
        iconType = PencilSquareIcon;
        break;
      case HistoryEntryType.OrderNote:
        bgColor = 'bg-amber-300';
        iconColor = 'text-amber-800';
        iconType = PencilSquareIcon;
        break;
      case HistoryEntryType.OrderFulfillment:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = TruckIcon;
        break;
      case HistoryEntryType.OrderCancellation:
        bgColor = 'bg-red-300';
        iconColor = 'text-red-800';
        iconType = XMarkIcon;
        break;
      case HistoryEntryType.OrderCouponApplied:
        bgColor = 'bg-emerald-200';
        iconColor = 'text-emerald-600';
        iconType = TagIcon;
        break;
      case HistoryEntryType.OrderCouponRemoved:
        bgColor = 'bg-red-300';
        iconColor = 'text-red-800';
        iconType = TagIcon;
        break;
      case HistoryEntryType.OrderModified:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = ShoppingCartIcon;
        break;
      case HistoryEntryType.OrderCustomerUpdated:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = ShoppingCartIcon;
        break;
      case HistoryEntryType.SubscriptionChange:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = TicketIcon;
        break;
      case HistoryEntryType.ParticipantNote:
        bgColor = 'bg-amber-300';
        iconColor = 'text-amber-800';
        iconType = PencilSquareIcon;
        break;
      case HistoryEntryType.ParticipantMessage:
        bgColor = 'bg-amber-300';
        iconColor = 'text-amber-800';
        iconType = PencilSquareIcon;
        break;
      case HistoryEntryType.ParticipantTransfer:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = ArrowsRightLeftIcon;
        break;
      case HistoryEntryType.StaffNote:
        bgColor = 'bg-amber-300';
        iconColor = 'text-amber-800';
        iconType = PencilSquareIcon;
        break;
      case HistoryEntryType.ChildPickedUp:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = UserCircleIcon;
        break;
      case HistoryEntryType.PickupcodeGenerated:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = QrCodeIcon;
        break;
      case HistoryEntryType.ChannelSetup:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = BuildingStorefrontIcon;
        break;
      case HistoryEntryType.SellerRegistered:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = BuildingStorefrontIcon;
        break;
      case HistoryEntryType.PaymentEmailSent:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = EnvelopeIcon;
        break;
      case HistoryEntryType.ParticipantCheckin:
        if (entry.data.status === 'PRESENT') {
          bgColor = 'bg-emerald-200';
          iconColor = 'text-emerald-600';
          iconType = CheckCircleIcon;
        } else {
          bgColor = 'bg-red-300';
          iconColor = 'text-red-800';
          iconType = XCircleIcon;
        }
        break;
      default:
        bgColor = 'bg-gray-50 dark:bg-slate-700';
        iconColor = 'text-gray-400 dark:text-slate-300';
        iconType = ArrowsRightLeftIcon;
        break;
    }
  }
  if (type === NotificationType.Note) {
    if (entry.isPublic) {
      bgColor = 'bg-gray-50 dark:bg-slate-700';
      iconColor = 'text-gray-400 dark:text-slate-300';
      iconType = EyeIcon;
    } else {
      bgColor = 'bg-violet-50';
      iconColor = 'text-violet-400';
      iconType = EyeSlashIcon;
    }
  }
  return <div className={clsx('flex items-center justify-center rounded-full border-gray-200 dark:border-slate-500 shrink-0', {
    'w-8 h-8': iconSize !== 'large' && [NotificationType.Notification].includes(type),
    'border w-10 h-10': iconSize === 'large' || [NotificationType.History, NotificationType.Note].includes(type)
    // 'border w-12 h-12': iconSize === 'large',
  }, bgColor)}>
      {React.createElement(iconType, {
      className: clsx(iconColor, {
        'w-4 h-4': type === NotificationType.Notification,
        'w-6 h-6': iconSize === 'large' || type !== NotificationType.Notification
        // 'w-8 h-8': iconSize === 'large',
      })
    })}
    </div>;
};
_c = NotificationIcon;
var _c;
$RefreshReg$(_c, "NotificationIcon");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;